import axios from "axios";
import { Message } from "element-ui";
/*富文本编辑图片上传配置*/
const uploadConfig = {
  action: "/api/file/uploadObjectObs", // 必填参数 图片上传地址
  methods: "POST", // 必填参数 图片上传方式
  name: "img", // 必填参数 文件的参数名
  size: 1024 * 1024 * 2, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon" // 可选 可上传的图片格式
};

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
  [
    {
      header: [1, 2, 3, 4, 5, 6, false]
    }
  ],
  ["bold", "italic", "underline", "strike"],
  // ['blockquote', 'code-block'],
  [
    {
      list: "ordered"
    },
    {
      list: "bullet"
    }
  ],
  [
    {
      script: "sub"
    },
    {
      script: "super"
    }
  ],
  [
    {
      indent: "-1"
    },
    {
      indent: "+1"
    }
  ],
  [
    {
      direction: "rtl"
    }
  ],
  [
    {
      color: []
    },
    {
      background: []
    }
  ],
  // [{
  // 	'font': []
  // }],
  [
    {
      align: []
    }
  ],
  ["clean"],
  ["link", "image"]
];
const handlers = {
  image: function image() {
    var self = this;
    console.log("input");
    var fileInput = this.container.querySelector("input.ql-image[type=file]");
    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      // 设置图片参数名
      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      }
      // 可设置上传图片的格式
      fileInput.setAttribute("accept", uploadConfig.accept);
      fileInput.classList.add("ql-image");
      // 监听选择文件
      fileInput.addEventListener("change", async function() {
        console.log(1);
        // 创建formData
        if (uploadConfig.size && fileInput.files[0].size >= uploadConfig.size) {
          Message.warning("图片大小不能超过2M");
          fileInput.value = "";
          return;
        }
        let formData = new FormData();
        formData.append("file", fileInput.files[0]);
        fileInput.value = "";
        //这里 请求
        Message({
          message: "正在上传"
        });
        try {
          const result = await axios.post(uploadConfig.action, formData);
          Message.closeAll();
          Message({
            message: result.message || "上传成功",
            type: "success"
          });
          var picPath = `https://sbc-images.xiangduyun.com${result.data}`;
          let length = self.quill.getSelection(true).index;
          //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
          self.quill.insertEmbed(length, "image", picPath);
          self.quill.setSelection(length + 1);
          fileInput.value = "";
        } catch (error) {
					console.log(error)
          Message({
            message: "上传失败",
            type: "error"
          });
        }
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  },
  video: function video() {
    var fileInput = this.container.querySelector("input.ql-image[type=file]");
    console.log(fileInput);
  }
};

function HandleCustomMatcher (node, Delta) {
  let ops = []
  Delta.ops.forEach(op => {
    if (op.insert && op.insert.image && op.insert.image.indexOf('data:image/png;base64,') == 0) {
      Message.warning("不允许粘贴图片，请手动上传");
    } else {
      // 文字转为纯文本
      ops.push({
        insert: op.insert
      })
    }
  })
  Delta.ops = ops
  return Delta
}

export default {
  placeholder: "请填写公告内容",
  theme: "snow", // 主题
  modules: {
    imageDrop: false,
    // 粘贴过滤
    clipboard: {
      matchers: [[Node.ELEMENT_NODE, HandleCustomMatcher]]
    },
    toolbar: {
      container: toolOptions, // 工具栏选项
      handlers: handlers // 事件重写
    }
  }
};
